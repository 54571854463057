<template>
  <div class="app-wrap">
    <div class="side-box">
      <el-menu
        :default-active="activeRouter"
         @select="handleSelect"
        class="el-menu-vertical-demo"
        background-color="#9F0C0C"
        text-color="#FFFFFF"
        active-text-color="#FFFFFF"
        :default-openeds="defaultOpeneds"
        :collapse="isCollapse"
      >
        <template v-for="item in appRouter">
          <router-link
            v-if="getRouterChildLength(item) < 2"
            :to="item.path"
            :key="item.path"
          >
            <el-menu-item :index="item.redirect">
              <i :class="[item.meta.icon]"></i>
              <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </router-link>
          <el-submenu
            v-else
            :index="item.path"
            :key="item.path"
            popper-class="sideBarPopper"
          >
            <template slot="title">
              <i :class="[item.meta.icon]"></i>
              <span slot="title">{{ item.meta.title }}</span>
            </template>
            <template v-for="child in item.children">
              <router-link
                v-if="!child.meta.hideMenu"
                :to="item.path + '/' + child.path"
                :key="child.path"
              >
                <el-menu-item :index="item.path + '/' + child.path">
                  <span slot="title">{{ child.meta.title }}</span>
                </el-menu-item>
              </router-link>
            </template>
          </el-submenu>
        </template>
      </el-menu>
    </div>
    <div class="mian-wrap">
      <div class="contentWrap">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { appRouter } from "@/router/router";
export default {
  data() {
    return {
      appRouter,
      isCollapse: false,
      activeRouter: "/",
      defaultOpeneds: [],
    };
  },

  mounted() {
      this.activeRouter = this.$route.path || '/'

    // 默认展开所以子菜单
    this.appRouter.forEach(route => {
      this.defaultOpeneds.push(route.path)
    });
  },

  methods: {
    // 获取路由children数
    getRouterChildLength(item) {
      if (!item.children) {
        return 0;
      } else {
        let arr = item.children.filter((i) => {
          return !i.meta.hideMenu;
        });
        return arr.length;
      }
    },

    handleSelect(index){
        this.activeRouter = index
    }
  },
};
</script>

<style lang="scss">
.app-wrap {
  position: absolute;
  top: 72px;
  width: 100%;
  bottom: 0;

  .side-box {
    float: left;
    position: relative;
    height: 100%;
    background-color:#F2F6FC;
    .el-menu {
        border-right: 0;
        height: 100%;

        a .el-menu-item {
            // 设置手标移动上激活颜色
            &:hover {
                background-color: #f30909 !important;
            }

        }
        // 点击
        a .is-active {
            background-color: #710b0b !important;
        }
        // 展开箭头
        .el-submenu__title{
            // 设置手标移动上激活颜色
            &:hover {
                background-color: #f30909 !important;
            }
            i{
                color: #ffffff;
                font-size: 16px;
            }
        }
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 210px;
    }
  }

  .mian-wrap {
    overflow: auto;
    height: 100%;
    .contentWrap {
      overflow-x: hidden;
      height: 100%;
      position: relative;
      overflow-y: auto;
      > div {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
}
</style>
